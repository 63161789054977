const theme = {
  primary: '#344B42',
  info: '#5CADFF',
  success: '#41C86E',
  warning: '#F68B50',
  error: '#F15A53',
  background: '#f5f5f5',
  white: '#FFFFFF',
  black: '#000000',
  gray: '#dbdbdb',
};

export type ThemeType = typeof theme;

export default theme;
