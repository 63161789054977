import Cookies from 'universal-cookie';
import crypto from 'crypto';

const cookies = new Cookies();

const option = {
  path: '/',
  domain:
    process.env.REACT_APP_ENVIRONMENT === 'development'
      ? 'localhost'
      : 'guams4u.com',
  secure: process.env.REACT_APP_ENVIRONMENT !== 'development',
} as const;

const getAccessToken = () => {
  return cookies.get('access_token') as string;
};

const getAccessLevel = () => {
  return cookies.get('access_level') as string;
};

const getCountry = () => {
  return cookies.get('country') as string;
};

const setAccessToken = (
  token: string,
  access_level: string,
  country: string
) => {
  const date = new Date(Date.now());

  date.setHours(date.getHours() + 1);

  cookies.set('access_token', token, {
    ...option,
    expires: date,
  });

  cookies.set('country', country, {
    ...option,
    expires: date,
  });

  cookies.set(
    'access_level',
    crypto.createHash('sha512').update(access_level).digest('hex'),
    {
      ...option,
      expires: date,
    }
  );
};

const getRefreshToken = () => {
  return cookies.get('refresh_token') as string;
};

const setRefreshToken = (token: string) => {
  const date = new Date(Date.now());

  date.setDate(date.getDate() + 7);

  cookies.set('refresh_token', token, { ...option, expires: date });
};

const removeToken = (type: 'ALL' | 'AT' | 'RT' | 'AL' | 'CR') => {
  switch (type) {
    case 'ALL':
      cookies.remove('access_token', option);
      cookies.remove('refresh_token', option);
      cookies.remove('access_level', option);
      cookies.remove('country', option);

      break;

    case 'AT':
      cookies.remove('access_token', option);

      break;

    case 'RT':
      cookies.remove('refresh_token', option);

      break;

    case 'AL':
      cookies.remove('access_level', option);
      break;

    case 'CR':
      cookies.remove('country', option);

      break;

    default:
      break;
  }
};

const tokenLib = {
  getCountry,
  getAccessToken,
  getAccessLevel,
  setAccessToken,
  getRefreshToken,
  setRefreshToken,
  removeToken,
};

export default tokenLib;
