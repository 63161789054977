import axios, { AxiosRequestConfig } from 'axios';

import tokenLib from '../../library/token.lib';

const BASE_URL = process.env.REACT_APP_SERVER_ADDRESS;

const axiosAPI = axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization:
      tokenLib.getAccessToken() !== undefined
        ? `Bearer ${tokenLib.getAccessToken()}`
        : '',
  },
  withCredentials: true,
});

axiosAPI.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    if (tokenLib.getRefreshToken() !== undefined) {
      await axios({
        method: 'GET',
        url: `${BASE_URL}/u/auth/decoded-token`,
        headers: {
          Authorization: `Bearer ${
            tokenLib.getAccessToken() === undefined
              ? ''
              : tokenLib.getAccessToken()
          }`,
        },
      })
        .then(() => {
          config.headers.Authorization = `Bearer ${
            tokenLib.getAccessToken() === undefined
              ? ''
              : tokenLib.getAccessToken()
          }`;
        })
        .catch(async (error) => {
          const response = error.response.data;

          if (!response) return config;

          if (
            response.status === 410 ||
            (response.status === 400 &&
              tokenLib.getRefreshToken() !== undefined)
          ) {
            await axios({
              method: 'GET',
              url: `${BASE_URL}/u/auth/refresh-token`,
              headers: {
                Authorization: `Bearer ${tokenLib.getRefreshToken()}`,
              },
            }).then((response) => {
              const accessToken: string = response.data.data.accessToken;

              config.headers.Authorization = `Bearer ${accessToken}`;

              tokenLib.setAccessToken(
                accessToken,
                response.data.data.access_level,
                response.data.data.country
              );
            });
          }
        });
    }

    return config;
  },
  (_error) => {
    tokenLib.removeToken('ALL');
  }
);

export const handleSignInAxiosAPI = (token: string) => {
  axiosAPI.defaults.headers.Authorization = `Bearer ${token}`;
};

export default axiosAPI;
