import { ChangeEvent, useEffect, useState } from 'react';
import cn from 'classnames';
import Color from 'color';
import styled, { CSSProperties } from 'styled-components';

type ItemProps = {
  src: string;
  file: File;
};

function Item({ src, file }: ItemProps) {
  if (!file) return <></>;

  const sizeInMB = (file.size / (1024 * 1024)).toFixed(2);

  return (
    <>
      <ImageItem href={src} target='_blank' title={file.name}>
        <span className={cn('name')}>{file.name}</span>
        <span className={cn('size')}>{`${sizeInMB}MB`}</span>
      </ImageItem>
    </>
  );
}

export type ImageUploadProps = {
  images: File[];
  multiple?: boolean;
  style?: CSSProperties;
  setImages: (state: File[]) => void;
};

export default function ImageUpload({
  images,
  multiple = false,
  style,
  setImages,
}: ImageUploadProps) {
  const [previewImages, setPreviewImages] = useState([] as string[]);

  const handleFile = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    if (!event.target.files || event.target.files.length === 0) return;

    const file = (event.target.files as FileList)[0];

    setImages([file]);
    setPreviewImages([URL.createObjectURL(file)]);
  };

  const handleFiles = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    if (!event.target.files || event.target.files.length === 0) return;

    const files = Array.from(event.target.files as FileList);

    setImages(files);

    let previews: string[] = [];

    for (const index in files) {
      previews = [...previews, URL.createObjectURL(files[index])];
    }

    setPreviewImages(previews);
  };

  useEffect(() => {
    if (images.length !== 0) {
      setPreviewImages(images.map((file) => URL.createObjectURL(file)));
    }
  }, []);

  return (
    <>
      <Box style={style}>
        <label className={cn('uploader')}>
          <div className={cn('uploader-btn')}>Choose File</div>

          <Input
            type='file'
            accept='image/*'
            multiple={multiple}
            onChange={multiple ? handleFiles : handleFile}
          />
        </label>

        <div className={cn('images')}>
          {previewImages.map((image, index) => {
            return <Item key={index} src={image} file={images[index]} />;
          })}
        </div>
      </Box>
    </>
  );
}

const Box = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;

  .uploader {
    width: fit-content;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;

    &-btn {
      width: fit-content;
      height: fit-content;
      padding: 10px 15px;
      font-size: 16px;
      font-weight: 600;
      color: #fff;
      background-color: #000;
      border: 2px solid #8f8f8f;
      border-radius: 5px;
      cursor: pointer;
    }
  }

  .images {
    width: 300px;
    height: fit-content;
    max-height: 300px;
    margin-left: 15px;
    overflow: auto;

    /* &::-webkit-scrollbar {
      width: 0.5rem;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${(prop) => prop.theme.primary};
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: ${(prop) =>
      Color(prop.theme.primary).alpha(0.3).hsl().toString()};
      border-radius: 4px;
    } */
  }
`;

const Input = styled.input`
  width: 0;
  height: 0;
  opacity: 0;
  margin: 0;
`;

const ImageItem = styled.a`
  width: 100%;
  height: 45px;
  display: flex;
  flex-direction: row;
  text-decoration: none;
  border-radius: 5px;
  background-color: ${Color('#acacac').whiten(5).hex()};
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }

  .name {
    font-size: 14px;
    margin: auto;
    margin-left: 15px;
    font-weight: 600;
    color: #000;
  }

  .size {
    font-size: 14px;
    margin: auto;
    margin-right: 15px;
    font-weight: 500;
    color: #8f8f8f;
  }
`;
